import React, {lazy, Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import {LoadingFallback} from "./components/layout/LoadingFallback";
import "./index.scss";
//import reportWebVitals from './reportWebVitals';

const App = lazy(() => import("./App"));

const root = ReactDOM.createRoot(
  document.getElementById('r') as HTMLElement
);

root.render(
  /*<React.StrictMode>*/
  <Suspense fallback={<LoadingFallback />}>
    <App/>
  </Suspense>
  /*</React.StrictMode>*/
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
