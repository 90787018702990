import React, {memo} from "react";

export const LoadingFallback = memo(() => <div style={{
  display:"flex",
  justifyContent: "center",
  alignItems:"center",
  fontFamily: "sans-serif",
  backgroundColor: "#fff",
  fontSize: "1.2rem",
  opacity: 0.7,
  position: "absolute", top: 0, left: 0, right: 0, bottom:0}}>Loading...</div>);